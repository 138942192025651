/* Your styles goes here. */

 
::selection {
  background: hsla(252, 17%, 54%, 0.52)
}
html {
  height: 100%;

}

body {
  height: 100%;
  background: #110C20 !important;
  min-width: 330px !important;
}

#root{
  height: 100%;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.grecaptcha-badge{
  display: none;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #8B859F;
  // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #1d1929;
}



* {
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
*::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  -webkit-appearance: none;
  display: none;
  width: 0 !important
}

