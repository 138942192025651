.levelup__planet {
    -webkit-animation: bop 100s ease-in-out;
    animation: bop 100s ease-in-out;
  }
  
  @-webkit-keyframes bop {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes bop {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  
  