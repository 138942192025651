body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Iranyekan Bold';
  src: local('AirbnbCerealLight'), url('./fonts/Qs_Iranyekan\ extrabold.ttf');
  /* Add additional font formats here for cross-browser support */
}

@font-face {
  font-family: 'Iranyekan';
  src: local('AirbnbCerealLight'), url('./fonts/Qs_Iranyekan.ttf');

  /* Add additional font formats here for cross-browser support */
}

@font-face {
  font-family: 'Iranyekan Light';
  src: local('AirbnbCerealLight'), url('./fonts/Qs_Iranyekan\ light.ttf');

  /* Add additional font formats here for cross-browser support */
}
@font-face {
  font-family: 'Iranyekan Thin';
  src: local('AirbnbCerealLight'), url('./fonts/Qs_Iranyekan\ thin.ttf');

  /* Add additional font formats here for cross-browser support */
}