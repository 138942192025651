/* Your styles goes here. */
body {
  margin: 0;
  padding: 0;
  // height: 100vh;
}

iframe {
  width: 100%;
  border: none;
  flex: 1
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: #faebd700;
}

::-webkit-scrollbar-track {
  background: #faebd700;
}


iframe body {
  background: black !important;
}

